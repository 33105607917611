import { ApolloClient, HttpLink, InMemoryCache } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import config from 'config/index';
import logger from 'technical/logger';

export interface AuthCookieStore {
  get: () => string;
  set: (newCookie: string) => void;
}

export function makeFetch(
  fetch: WindowOrWorkerGlobalScope['fetch'],
  loggerInstance: typeof logger,
  renewAccessToken: () => Promise<string | null>,
  authCookieStore?: AuthCookieStore,
): WindowOrWorkerGlobalScope['fetch'] {
  return (input, init) => {
    return fetch(input, init).then(async (response) => {
      const json = await response.json();
      if (json.errors && json.errors[0]?.extensions?.code === 'access-denied') {
        loggerInstance.info('Renewing token');
        const newToken = await renewAccessToken();
        loggerInstance.info('Token renewed!');
        // Updating headers with new token
        return fetch(input, {
          ...init,
          headers: {
            ...init?.headers,
            authorization: `Bearer ${newToken}`,
          },
        });
      }

      const cookieHeader = response.headers.get('set-cookie');

      if (cookieHeader && authCookieStore) {
        authCookieStore.set(cookieHeader);
      }

      // Recreating json ad text method that ca be called only one beefore forwardig
      return {
        ...response,
        json: () => Promise.resolve(json),
        text: () => Promise.resolve(JSON.stringify(json)),
      };
    });
  };
}

/**
 * Create a new Apollo client
 *
 * Auto-injects and renews access token using the provided functions.
 */
export function makeApolloClient(
  locale: string,
  getAccessToken: () => string | null,
  renewAccessToken: () => Promise<string | null>,
  authCookieStore?: AuthCookieStore,
) {
  const authLink = setContext((_, { headers }: any) => {
    // get the authentication token from auth service if it exists
    const token = getAccessToken();
    // return the headers to the context so httpLink can read them
    return {
      headers: {
        ...headers,
        cookie: authCookieStore?.get(),
        // if token does not exist do not set authorization header
        ...(token && { authorization: `Bearer ${token}` }),
        'x-hasura-preferred-lang': locale,
      },
    };
  });

  const httpLink = new HttpLink({
    uri: `${config.graphqlUri}/v1/graphql`,
    credentials: 'include',
    // Custom fetch to handle reconnection on jwt expired
    fetch: makeFetch(fetch, logger, renewAccessToken, authCookieStore),
  });

  return new ApolloClient({
    ssrMode: typeof window === 'undefined',
    link: authLink.concat(httpLink),
    cache: new InMemoryCache(),
  });
}
