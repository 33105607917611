import gql from 'graphql-tag';

export const QUERY_MY_USER = gql`
  query QueryMyUser {
    userPrivate {
      user {
        firstName
        lastName
        hasSeenOnboarding
        hasAcceptedPolicy
      }
      id
      email
      isAdmin
      isActive
      createdAt
      updatedAt
      type
    }
    roles
  }
`;

export const SIGN_IN_USER = gql`
  mutation SignIn($email: String!, $password: String!) {
    signIn(email: $email, password: $password) {
      success
      accessToken
      message
    }
  }
`;

export const RENEW_TOKEN = gql`
  mutation RefreshToken {
    queryRefreshToken {
      success
      accessToken
      message
    }
  }
`;

export const LOGOUT_USER = gql`
  mutation LogoutUser {
    logout {
      success
    }
  }
`;

export const SET_PROGRESS = gql`
  mutation SetProgress($moduleId: uuid!, $progression: Int!) {
    insert_userModuleProgress_one(
      object: { progression: $progression, moduleId: $moduleId }
      on_conflict: {
        constraint: c_user_module_progress_user_id_module_id
        update_columns: [progression]
      }
    ) {
      id
    }
  }
`;

export const SET_HAS_SEEN_ONBOARDING = gql`
  mutation SetHasSeenOnboarding($userId: uuid!) {
    update_user_by_pk(
      pk_columns: { id: $userId }
      _set: { hasSeenOnboarding: true }
    ) {
      id
    }
  }
`;

export const UPDATE_USER_MODULE_PROGRESS = gql`
  mutation UpdateUserModuleProgress($moduleId: ID!, $progression: Int!) {
    updateUserModuleProgression(
      moduleId: $moduleId
      progression: $progression
    ) {
      success
    }
  }
`;

export const MUTATION_SAVE_USER_SLOT = gql`
  mutation acceptPolicy {
    acceptPolicy {
      success
    }
  }
`;
