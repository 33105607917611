import React, { createContext, useContext, useState } from 'react';
import { UserWithPrivateInfos } from './user/types/user';

export interface AppContextAttributes {
  user: UserWithPrivateInfos | null;
  isConnected: boolean;
  setHasAcceptedPolicy?: React.Dispatch<
    React.SetStateAction<boolean | undefined>
  >;
}

const AppContext = createContext<AppContextAttributes>({
  user: null,
  isConnected: false,
});

export const useAppContext = () => useContext(AppContext);

interface Props {
  children: React.ReactElement;
  user: UserWithPrivateInfos | null;
}

export function AppProvider({ children, user }: Props) {
  const [hasAcceptedPolicy, setHasAcceptedPolicy] = useState(
    user?.hasAcceptedPolicy,
  );

  return (
    <AppContext.Provider
      value={{
        isConnected: user !== null,
        user: user
          ? {
              ...user,
              hasAcceptedPolicy,
            }
          : null,
        setHasAcceptedPolicy,
      }}
    >
      {children}
    </AppContext.Provider>
  );
}
