if (!process.env.NEXT_PUBLIC_GRAPHQL_URI) {
  throw new Error('Set NEXT_PUBLIC_GRAPHQL_URI');
}

const config = {
  // Try with the server-side SERVER_GRAPHQL_URI variable first -
  // Useful to override NEXT_PUBLIC_GRAPHQL_URI when inside a docker
  // container and localhost cannot be reached
  graphqlUri:
    process.env.SERVER_GRAPHQL_URI || process.env.NEXT_PUBLIC_GRAPHQL_URI,

  cookie: {
    domain: process.env.NEXT_PUBLIC_COOKIE_DOMAIN,
  },

  oidc: {
    p2024ConnectLogin: process.env.NEXT_PUBLIC_P2024CONNECT_LOGIN_URI || '',
    azureLogin: process.env.NEXT_PUBLIC_AZURE_LOGIN_URI || '',
    enabled: process.env.NEXT_PUBLIC_OIDC_P2024_CONNECT_ENABLED === 'true',
  },

  public: {
    backOfficeUrl: process.env.NEXT_PUBLIC_BACK_OFFICE_URI,
    firstConnectionPageEnabled:
      process.env.NEXT_PUBLIC_FIRST_CONNECTION_ENABLED === 'true',
  },

  sponsor: {
    footer: process.env.NEXT_PUBLIC_SPONSOR_DOMAIN_JS,
    footerStyle: process.env.NEXT_PUBLIC_SPONSOR_DOMAIN_STYLE,
  },

  buckets: {
    public: process.env.NEXT_PUBLIC_BUCKET_URI_PUBLIC,
  },
};

export default config;
