import {
  AuthInitialSession,
  AuthService,
} from 'business/user/services/authentication';
import { ServerResponse } from 'http';
import { AuthCookieStore, makeApolloClient } from 'technical/graphql/client';
import {
  getSerializedAuthCookie,
  isAuthCookie,
  mergeCookies,
} from 'technical/cookie';

/**
 * Create a new auth / apolloClient pair
 */
export function makeGraphQLApi(
  locale: string,
  authCookieStore?: AuthCookieStore,
  initialSession?: AuthInitialSession,
) {
  let maybeAuthService: AuthService | null = null;

  const apolloClient = makeApolloClient(
    locale,
    () => (maybeAuthService ? maybeAuthService.getAccessToken() : null),
    () =>
      maybeAuthService
        ? maybeAuthService.renewAccessToken()
        : Promise.resolve(null),
    authCookieStore,
  );

  const authService = new AuthService(apolloClient, initialSession);

  maybeAuthService = authService;

  return { apolloClient, authService };
}

/**
 * Create a basic store that can retrieve and set a refresh token cookie
 */
export function makeCookieStore(
  res: ServerResponse,
  refreshToken?: string,
  authSource?: string,
) {
  let authCookie = refreshToken
    ? getSerializedAuthCookie({
        refreshToken: refreshToken ?? '',
        authSource: authSource ?? '',
      })
    : '';

  return {
    get() {
      return authCookie;
    },
    set(newCookie: string) {
      res.setHeader('set-cookie', newCookie);
      const newParsedCookie = mergeCookies(newCookie, authCookie);
      if (isAuthCookie(newParsedCookie)) {
        authCookie = getSerializedAuthCookie(newParsedCookie);
      }
    },
  };
}
