import { ApolloClient } from '@apollo/client';
import {
  LOGOUT_USER,
  RENEW_TOKEN,
  SIGN_IN_USER,
} from 'business/user/services/queries.gql';

export interface AuthInitialSession {
  accessToken: string;
}

/**
 * Manages the user authentication session
 *
 * Registers the current access token, and is able to refresh it when
 * necesary
 */
export class AuthService {
  private client: ApolloClient<unknown>;

  private accessToken: string | null = null;

  // Use a promise cache system to avoid multi call asking token refresh at the same time
  private cachedRenewToken: Promise<string | null> | null = null;

  public constructor(
    apolloClient: ApolloClient<unknown>,
    initialSession?: AuthInitialSession,
  ) {
    this.client = apolloClient;

    if (initialSession) {
      this.initWithSession(initialSession);
    }
  }

  public initWithSession(initialSession: AuthInitialSession) {
    this.accessToken = initialSession.accessToken;
  }

  private persistAuth(token: string) {
    this.accessToken = token;
  }

  private async unpersistAuth() {
    this.accessToken = null;
    await this.client.mutate({
      mutation: LOGOUT_USER,
    });
  }

  public async signIn(email: string, password: string): Promise<boolean> {
    const { data } = await this.client.mutate({
      mutation: SIGN_IN_USER,
      variables: {
        email,
        password,
      },
    });

    if (data?.signIn?.success) {
      // 1. Store Token
      this.persistAuth(data.signIn.accessToken);
      return true;
    }

    throw new Error(data.signIn.message);
  }

  public async logOut() {
    await this.unpersistAuth();
    // @TODO Add observers here
  }

  public isAuthenticated() {
    return !!this.accessToken;
  }

  public getAccessToken() {
    return this.accessToken;
  }

  private async renewAccessTokenRequest() {
    // Do not launch the next request with an access token, it may lead
    // to an infinite loop if the current access token is outdated
    this.accessToken = null;

    try {
      const { data } = await this.client.mutate({
        mutation: RENEW_TOKEN,
        fetchPolicy: 'no-cache',
      });

      if (data.queryRefreshToken.success) {
        this.persistAuth(data.queryRefreshToken.accessToken);
        this.cachedRenewToken = null;
        return this.getAccessToken();
      }

      if (
        ['login-required', 'invalid-refresh-token'].includes(
          data.queryRefreshToken.message,
        )
      ) {
        this.accessToken = null;
        this.cachedRenewToken = null;
        return null;
      }

      throw new Error(data.queryRefreshToken.message);
    } catch (err) {
      // avoid keep renewtoken into error state
      this.cachedRenewToken = null;
      throw err;
    }
  }

  public async renewAccessToken() {
    if (this.cachedRenewToken) {
      return this.cachedRenewToken;
    }

    this.cachedRenewToken = this.renewAccessTokenRequest();

    return this.cachedRenewToken;
  }
}
