import { ApolloProvider } from '@apollo/client';
import { makeGraphQLApi } from 'business/graphql/client';
import { AppProvider } from 'business/AppBootstrapper';
import ThemeProvider from 'lms-ui/theme-provider';
import { appWithTranslation, useTranslation } from 'next-i18next';
import type { AppProps } from 'next/app';
import { useEffect } from 'react';
import 'lms-ui/globals.css';
import './App.scss';
import config from 'config/index';

function MyApp({ Component, pageProps }: AppProps) {
  const { session, user } = pageProps;
  const { i18n } = useTranslation();
  const { apolloClient, authService } = makeGraphQLApi(i18n.resolvedLanguage);

  useEffect(() => {
    if (session && user) {
      authService.initWithSession(session);
    }
  }, [session, user, authService]);

  useEffect(() => {
    document.cookie = `NEXT_LOCALE=${
      i18n.resolvedLanguage || ''
    }; SameSite=None; Secure; path=/; Domain=${config.cookie.domain}`;
  }, [i18n.resolvedLanguage]);

  return (
    <ThemeProvider theme={{}}>
      <AppProvider user={user}>
        <ApolloProvider client={apolloClient}>
          <Component {...pageProps} />
        </ApolloProvider>
      </AppProvider>
    </ThemeProvider>
  );
}

export default appWithTranslation(MyApp);
